.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.heder__section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  color: white;
  font-size: 20px;
  font-weight: 600;
}
/* .body__center {
  display: grid;
  place-items: center;
  .title {
    font-size: 50px;
    font-family: "Michroma", sans-serif;
    font-weight: 600;
    margin-bottom: 20px;
    background: radial-gradient(circle, #ffffff, #5c6479);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
  .subtitle {
    font-family: "Michroma", sans-serif;
    width: 40%;
    font-size: 16px;
    font-weight: 600;
    color: #5c6479;
  }
  .input-container {
    display: flex;
    margin-top: 20px;
    padding: 3px;
    background: linear-gradient(90deg, #F42A8B, #144EE3);
    border-radius: 5px;
}

.url-input {
    flex-grow: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 5px 0 0 5px;
    font-size: 16px;
    outline: none;
}

.submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 0 30px 30px 0;
    background-color: #144EE3;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #0f3eb3;
}
} */


.body__center {
  display: grid;
  place-items: center;
  margin-top: 15%;
}

.body__center .title {
  font-size: 50px;
  font-family: "Michroma", sans-serif;
  font-weight: 600;
  margin-bottom: 20px;
  background: radial-gradient(circle, #ffffff, #5c6479);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.body__center .subtitle {
  font-family: "Michroma", sans-serif;
  width: 40%;
  font-size: 16px;
  font-weight: 600;
  color: #5c6479;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.is_error {
  border: 2px solid transparent !important;
  animation: gradientAnimation 3s ease infinite;
  background-image: linear-gradient(90deg, #ff0000, #ffffff, #ff0000) !important;

}
.searchbox-wrap{
  display     : flex; 
  width       : 500px;  
  margin-top  : 2%;
  margin-left : auto;
  margin-right: auto;
  border-radius: 5px;
  border: 2px solid transparent;
  animation: gradientAnimation 3s ease infinite;
  background-image: linear-gradient(90deg, #ffffff, #5c6479, #ffffff);
  background-size: 200% 100%;
  background-position: 0% 50%;
    
  
  input{
     flex                             : 1;
     padding                          : 12px 20px;
     font-size                        : 1em;
     font-family: "Michroma", sans-serif;
     
     -webkit-border-top-left-radius   : 5px;
     -webkit-border-bottom-left-radius: 5px;
     -moz-border-radius-topleft       : 5px;
     -moz-border-radius-bottomleft    : 5px;
     border-top-left-radius           : 5px;
     border-bottom-left-radius        : 5px;
     box-shadow                       : none;
     border                           : none;
     box-shadow                       : 2px 4px 6px rgba(0, 0, 0, 0.19);

  }
  
  button{
    padding-right                     : 10px;
    
    background-color                  : #fff;
    -webkit-border-top-right-radius   : 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright       : 5px;
    -moz-border-radius-bottomright    : 5px;
    border-top-right-radius           : 5px;
    border-bottom-right-radius        : 5px;
    box-shadow                        : 5px 4px 6px rgba(0, 0, 0, 0.19);
    border                            : none;
    cursor                            : pointer; 
    cursor                            : hand;
    font-family: "Michroma", sans-serif;
    
    span{
      margin-left: 5px;
            padding: 12px 45px;
            font-size: 0.9em;
            font-weight: 300;
            color: #fff;
            background-color: #00091eeb;
            border-radius: 6px;
            box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.19);
      
      &:hover{
        background-color: #3d465e;
        box-shadow      : 2px 2px 4px rgba(0, 0, 0, 0.19);
      }
      
    }
  }
  .disabled_button {
    cursor: not-allowed;
  }
}

.shorten_url {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  color: #5c6479;
  font-family: "Michroma", sans-serif;
  font-size: 16px;
  font-weight: 600;
  width: 500px;
  margin-left: auto;
  margin-right: auto;

  .link_text{
    /* margin-bottom: 10px; */
    color: #5c6479;
    
  }
  .link_text:hover{
    color: #ffffff;
  }

  .text_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    gap: 12px;
    .icon-class {
      cursor: pointer;
    }
  }
  
}

.total_shorten {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #5c6479;
  font-family: "Michroma", sans-serif;
  font-size: 50px;
  font-weight: 600;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  .total_text{
    color: #5c6479;
  }
  .total_text:hover{
    color: #ffffff;
  }
}



@media (max-width: 768px) {
  .body__center {
    margin-top: 5%;
  }

  .body__center .title {
    font-size: 28px;
  }

  .body__center .subtitle {
    font-size: 12px;
  }

  .searchbox-wrap {
    flex-direction: column;
  }

  .searchbox-wrap input {
    border-radius: 5px 5px 0 0;
  }

  .searchbox-wrap button {
    border-radius: 0 0 5px 5px;
    padding-right: 0;
  }

  .searchbox-wrap button span {
    width: 100%;
    padding: 12px 0;
  }

  .shorten_url {
    font-size: 12px;
  }

  .total_shorten {
    font-size: 28px;
  }
}