.container {
	margin: 0 auto;
	margin-top: 50px;
	width: 980px;
}

ul.task-items {
	li.item {
		display: flex;
		align-items: center;		
		margin: 20px 0;
		padding: 20px;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

		&.type1 .task .icon {
			background: #9575cd;
		}
		
		&.type2 .task .icon {
			background: #f48fb1;
		}
		
		&.type3 .task .icon {
			background: #9575cd;
		}
		
		&.type4 .task .icon {
			background: #4FC3F7;
		}

		.task {
			display: flex;
			align-items: center;
			width: 240px;

			.icon {
				background: #bdbdbd;
				width: 50px;
				height: 50px;
				border-radius: 5px;
			}

			.name {
				background: #eeeeee;
				margin-left: 20px;
				width: 180px;
				height: 25px;
				border-radius: 15px;
			}
		}

		.status {
			display: flex;
			align-items: center;
			font-size: 1em;
			color: #2e7d32;
			width: 145px;
			margin-left: 30px;
			
			.icon {
				background: #2e7d32;
				margin-right: 10px;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				
				&.risk {
					background: red;	
				}
				
				&.warning {
					background: #FFA000;	
				}
				
				&.off {
					background: #BF360C;	
				}
			}
		}

		.progress {
			width: 190px;
			progress {
				display: block;
				margin-left: 0;
				-webkit-appearance: none;
				height: 12.5px;
				width: 142.5px;
			}

			progress::-webkit-progress-bar {
				background-color: #eeeeee;
				border-radius: 5px;
			}

			progress::-webkit-progress-value {
				background-color: #4dd0e1;
				border-radius: 5px;
			}
		}

		.dates {
			width: 150px;
		}

		.dates, .priority {
			.bar {
				background: #eeeeee;				
				width: 100px;
				height: 25px;
				border-radius: 15px;
			}
		}

		.priority {
			width: 127.5px;
			.bar {
				background: #ffcdd2;
			}
		}

		.user {
			width: 30px;
			img {
				border-radius: 50%;
			}
		}
	}
}
